import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.component.html',
  styleUrls: ['./idle.component.scss']
})
export class IdleComponent {
  totalSeconds: number = 300;
  timerInterval: any;

  constructor(
    private accountService: AccountService,
    private titleService: Title,
    public router: Router,
    public dialogRef: MatDialogRef<any>
  ) {
    this.startTimer();
  }
  get minutesLeft(): number {
    return Math.floor(this.totalSeconds / 60);
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => {
      this.totalSeconds--;

      const minutes = String(Math.floor(this.totalSeconds / 60)).padStart(2, '0');
      const seconds = String(this.totalSeconds % 60).padStart(2, '0');
      console.log(`Time Left: ${minutes}:${seconds}`);

      if (this.totalSeconds <= 0) {
        clearInterval(this.timerInterval);
        this.logout();
      }
    }, 1000);
  }

  cancel(): void {
    clearInterval(this.timerInterval);
    this.dialogRef.close();
  }

  logout(): void {
    clearInterval(this.timerInterval);
    this.accountService.logout();
    this.router.navigateByUrl('');
  }
}
