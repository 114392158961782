import { SharedService } from './../../services/shared.service';
import { Component, OnInit } from '@angular/core';
import { NewResourcesService } from 'src/app/services/new-resources.service';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  constructor(public sharedService: SharedService,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }
  sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
