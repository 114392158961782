<div class="container" style="background-color: #102a41;">
  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>
  <div mat-dialog-title class="logo" style="
  display: flex;

  justify-content: center;">
    <img src="../../../assets/img/loggo.png" alt="">
  </div>
  <div mat-dialog-content style="color: #fff">We noticed you have been idle on the website for a bit. For your security, we will
    automatically log you out in <b>{{minutesLeft}}</b> minutes.</div>
  <div class="col-md-6" mat-dialog-actions style="width: 100%;
display: flex;
justify-content: center; gap: 12px">
    <!-- <button mat-button mat-dialog-close (click)="logout()" class="btn-primary">Logout</button> -->

    <button mat-button mat-dialog-close style="right: 0;     color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;" class="btn-success">Stay</button>
  </div>
</div>
