<div class="contact">
  <div class="container">
      <div style="height: fit-content;">
          <p><i class="fa fa-phone-square"></i>(888) 315-3265</p>
      </div>
      <div style="height: fit-content;">
          <p><i class="fa fa-envelope"></i>SUPPORT&#64;GRASSROOTSLABS.COM</p>
      </div>
  </div>
</div>
<div class="footer">
  <div class="container">
    <div class="col-md-3 social">
      <a href="https://www.instagram.com/grassroots_labs/" target="_blank">
        <i class="fa fa-instagram"></i>
      </a>
      <a href="https://www.youtube.com/@grassrootslabs" target="_blank">
        <i class="fa fa-youtube-play"></i>
      </a>
      <a href="https://www.facebook.com/GrassrootsLabs/" target="_blank">
        <i class="fa fa-facebook-square"></i>
      </a>
      <a href="https://twitter.com/GrassrootsLabs" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-twitter-x" viewBox="0 -2 18 18">
          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
        </svg>
      </a>
      <!--            <a href="https://plus.google.com/u/0/b/101847991060883428283/?pageId=101847991060883428283" target="_blank">-->
      <!--                <i class="fa fa-google-plus-square"></i>-->
      <!--            </a>-->
    </div>


    <div class="col-md-6 text-center" style="padding: 0px; margin-top: 10px;">

      <!-- <a href="https://docs.google.com/document/d/1epaTPT1dprvsWmS6bcvZOPJHavYU1cLGREIw2jj2NcU/edit" target="_blank"
         class="iubenda-white iubenda-embed"
         title="Privacy Policy">Privacy Policy</a>

      <script type="text/javascript">
        (function (w, d) {
          var loader = function () {
            var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
            s.src = "//cdn.iubenda.com/iubenda.js";
            tag.parentNode.insertBefore(s, tag);
          };
          if (w.addEventListener) {
            w.addEventListener("load", loader, false);
          } else if (w.attachEvent) {
            w.attachEvent("onload", loader);
          } else {
            w.onload = loader;
          }
        })(window, document);
      </script>

      <a  href="https://docs.google.com/document/d/1W7mIMNl2xOSCy7iPtlvAnpvT6DurZwZF0Hk37vCrv-4/edit" target="_blank">Terms of Service</a>

      <a href="https://help.grassrootslabs.com" target="_blank">Help Center</a> -->

      <div style="display: flex; align-items: center; justify-content: space-evenly; line-height: normal; flex-wrap: wrap;" class="dynamicFooterList" >
        <ul *ngFor="let option of sharedService.navigationList | async | navigation : 'footer'" style="padding-left: 0px; display: flex;">
          <a *ngIf="!option.isLink" [routerLink]="option.name">
            {{option.name}}
          </a>
          <a *ngIf="option.isLink" [href]="sanitizeUrl(option.slug)" target="_blank">
            {{option.name}}
          </a>
          <li class="hidden-sm hidden-md hidden-lg">|</li>
        </ul>
      </div>

      <p class="copyright" style="margin-bottom: 5px;">
        Copyright © {{ currentYear }} Grassroots Labs CORP.
      </p>
    </div>
    <div class="col-md-3 text-right">
      <a href="#top" class="btn btn-primary">
        <i class="fa fa-caret-up"></i>
      </a>
    </div>
  </div>
</div>
