<div class="popup forms" fxFlex="1 1 auto" fxLayout="column">

  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

  <div class="logo col-md-12">
    <div class="col-md-3">
      <img src="../../../assets/img/logo_white.png" alt="grassroots logo" class="img-responsive" />
    </div>
    <div class="col-md-7">
      <h1>User Registration</h1>
    </div>
  </div>
  <div fxFlex="1 1 100%" flexLayout="column" style="clear: left;">
    <!-- <ng-scrollbar class="registered-theme"
                  thumbClass="blue-scrollbars"
                  track="vertical"> -->
    <form name="form" class="form-horizontal" (submit)="register()" [formGroup]="registrationForm"
      novalidate="novalidate" autocomplete="off">
      <div [ngClass]="{'mobileVersion': innerWidth < 767.98}">
        <!-- first column of registration -->
        <div class="col-sm-6">

          <!-- First Name -->
          <div class="form-group">
            <label for="firstNameid" container="body" placement="bottom"
              ngbTooltip="Please use your legal first and last name when registering for an account."
              class="col-sm-3 control-label">First Name *</label>
            <div class="col-sm-9">
              <input name="firstName" formControlName="firstName" type="text" class="form-control" id="firstNameid"
                placeholder="First Name">

            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="submitted && registrationForm.controls?.firstName?.invalid">
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls?.firstName?.errors?.required">
                <b>Error:</b> Please add a first name.
              </div>
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls?.firstName?.errors?.pattern">
                <b>Error:</b>First name can only contain letters.
              </div>
            </div>
          </div>

          <!-- Middle Name -->
          <div class="form-group">
            <label for="mnameid" class="col-sm-3 control-label">Middle Name</label>
            <div class="col-sm-9">
              <input formControlName="middleName" type="text" class="form-control" id="mnameid"
                placeholder="Middle Name">
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="submitted && registrationForm.controls?.middleName?.invalid">
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls?.middleName?.errors?.pattern">
                <b>Error:</b> Middle name can only contain letters.
              </div>
            </div>
          </div>

          <!-- Last Name -->
          <div class="form-group">
            <label for="lnameid" class="col-sm-3 control-label" container="body" placement="bottom"
              ngbTooltip="Please use your legal first and last name when registering for an account.">Last Name
              *</label>
            <div class="col-sm-9">
              <input name="lastName" formControlName="lastName" type="text" class="form-control" id="lnameid"
                placeholder="Last Name">

            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="submitted && registrationForm.controls?.lastName?.invalid">
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls?.lastName?.errors?.required">
                <b>Error:</b> Please add a last name.
              </div>
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls?.lastName?.errors?.pattern">
                <b>Error:</b>Last name can only contain letters.
              </div>
            </div>
          </div>

          <!-- suffix -->
          <div class="form-group">
            <label for="suffixH" class="col-sm-3 control-label">Suffix</label>
            <div class="col-sm-9">
              <mat-form-field appearance="fill" class="mat-form-wrapper">
                <mat-select #selectElementSuffix class="custom-select-registration" placeholder="Suffix"
                  formControlName="suffix" (selectionChange)="setSuffix(selectElementSuffix.value)" [disabled]="false">
                  <mat-option *ngFor="let suffixItem of suffix" [value]="suffixItem">
                    {{ suffixItem }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- Email -->
          <div class="form-group">
            <label for="inputEmail" class="col-sm-3 control-label">Email *</label>
            <div class="col-sm-9">
              <input formControlName="email" (blur)="checkDuplicate()" type="email" class="form-control" id="inputEmail"
                placeholder="Email">
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="registrationForm.controls.email?.invalid  && submitted">
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls?.email.errors?.required">
                <b>Error:</b> Please add an email address.
              </div>
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls?.email.errors?.email">
                <b>Error:</b> Email is invalid.
              </div>
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="duplicateEmail">
              <div class="alert alert-danger" role="alert"><b>Error:</b> This email is already taken.</div>
            </div>
          </div>


          <!-- Home Phone -->
          <div class="form-group">
            <label for="homePhone" class="col-sm-3 control-label">Home Phone</label>
            <div class="col-sm-9">
              <input phone-input name="homePhone" class="form-control" formControlName="homePhone" type="text"
                id="homePhone" placeholder="Home Phone" />
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="registrationForm?.controls?.homePhone.errors?.pattern">
              <div class="alert alert-danger" role="alert">
                <b>Error:</b> Country codes not allowed. 10 digit phone numbers only.
              </div>
            </div>
            <div class="form-group col-sm-12 pull-right"
              *ngIf="registrationForm?.controls?.homePhone.errors?.maxlength">
              <div class="alert alert-danger" role="alert">
                <b>Error:</b> 10 digit phone numbers only.
              </div>
            </div>
          </div>

          <!-- Work Phone -->
          <div class="form-group">
            <label for="workPhone" class="col-sm-3 control-label">Work Phone</label>
            <div class="col-sm-9">
              <input phone-input name="workPhone" class="form-control" formControlName="workPhone" type="text"
                id="workPhone" placeholder="Work Phone" />
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="registrationForm?.controls?.workPhone.errors?.pattern">
              <div class="alert alert-danger" role="alert">
                <b>Error:</b> Country codes not allowed. 10 digit phone numbers only.
              </div>
            </div>
            <div class="form-group col-sm-12 pull-right"
              *ngIf="registrationForm?.controls?.workPhone.errors?.maxlength">
              <div class="alert alert-danger" role="alert">
                <b>Error:</b> 10 digit phone numbers only.
              </div>
            </div>
          </div>

          <!-- Mobile / Cell Phone -->
          <div class="form-group">
            <label for="mobilePhone" class="col-sm-3 control-label">Cell Phone *</label>
            <div class="col-sm-9">
              <input phone-input name="mobilePhone" class="form-control" formControlName="mobilePhone" type="text"
                id="mobilePhone" placeholder="Mobile Phone" autocomplete="on" />
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="submitted && (registrationForm?.errors?.reqiredOne)">
              <div class="alert alert-danger" role="alert">
                <b>Error:</b> Please add a mobile number.
              </div>
            </div>
            <div class="form-group col-sm-12 pull-right"
              *ngIf="registrationForm?.controls?.mobilePhone.errors?.pattern">
              <div class="alert alert-danger" role="alert">
                <b>Error:</b> Country codes not allowed. 10 digit phone numbers only.
              </div>
            </div>
            <div class="form-group col-sm-12 pull-right"
              *ngIf="registrationForm?.controls?.mobilePhone.errors?.maxlength">
              <div class="alert alert-danger" role="alert">
                <b>Error:</b> 10 digit phone numbers only.
              </div>
            </div>
          </div>




          <!-- Password -->
          <div class="form-group">
            <label for="inputPassword3" class="col-sm-3 control-label">Password *</label>
            <div class="col-sm-9">
              <input (input)="analyze($event.target.value)" (focus)="showSuggestions = true"
                (blur)="showSuggestions = false" formControlName="password" [type]="showPassword ? 'text' : 'password'"
                autocomplete='new-password' name="password" id="inputPassword3" class="form-control"
                placeholder="Password">
              <button mat-icon-button (click)="showPassword = !showPassword" type="button" id="visibilityButton">
                <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
              </button>
            </div>
            <div class="form-group col-md-12 pull-right mobileErrorView"
              *ngIf="registrationForm.controls?.password?.touched && registrationForm.controls?.password?.invalid">
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls?.password.errors?.required">
                <b>Error:</b> Please fill password field.
              </div>
              <div class="alert alert-danger" role="alert"
                *ngIf="registrationForm.controls?.password.errors?.minlength"><b>Error:</b> Your password must be at
                least 8 characters.</div>
            </div>
            <div class="form-group col-md-12 pull-right mobileErrorView" *ngIf="submitted">
              <div class="alert alert-danger" role="alert" *ngIf="paswordInvalid"><b>Error:</b> Your password must
                include at least one uppercase letter, one lowercase letter,
                one number digit and one special character (&#64;,!,#,$,%,^,&,*).
              </div>
            </div>
          </div>



          <!-- Confirm Password -->
          <div class="form-group">
            <label for="inputConfirm3" class="col-sm-3 control-label">Confirm Password*</label>
            <div class="col-sm-9">
              <input (input)="confirmPasswordAnalyze($event.target.value)" (focus)="showSuggestions2 = true"
                (blur)="showSuggestions2 = false" compare-to="password" name="confirm" formControlName="confirmPassword"
                [type]="showPassword ? 'text' : 'password'" class="form-control" id="inputConfirm3"
                ng-pattern="strongPwd" ng-minlength="8" maxlength="26" placeholder="Confirm Password">
            </div>
            <div class="form-group col-md-12 pull-right mobileErrorView"
              *ngIf="registrationForm.controls?.confirmPassword?.touched && registrationForm.controls?.confirmPassword?.invalid">
              <div class="alert alert-danger" role="alert"
                *ngIf="registrationForm.controls?.confirmPassword.errors?.required"><b>Error:</b> Please fill password
                field.</div>
              <div class="alert alert-danger" role="alert"
                *ngIf="registrationForm.controls?.confirmPassword.errors?.minlength"><b>Error:</b> Your password must be
                at least 8 characters.</div>
            </div>
            <div class="form-group col-md-12 pull-right mobileErrorView" *ngIf="submitted">
              <div class="alert alert-danger" role="alert" *ngIf="confirmPasswordInvalid"><b>Error:</b> Your password
                must include at least one uppercase letter, one lowercase letter,
                one number digit and one special character (&#64;,!,#,$,%,^,&,*).
              </div>
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="registrationForm.hasError('passwordsNotEqual')">
              <div class="alert alert-danger" role="alert"><b>Error:</b> Your passwords do not match. Please confirmed
                them and try again.</div>
            </div>
          </div>

        </div>

        <!-- second half of the column of registration -->
        <div class="col-sm-6">

          <!-- Gender -->
          <div class="form-group">
            <label class="col-sm-3 control-label">Gender *</label>
            <div class="col-sm-9">
              <fieldset>
                <div class="radio radio-info radio-inline">
                  <input formControlName="gender" type="radio" name="gender" value="2" id="female">
                  <label for="female"> Female </label>
                </div>

                <div class="radio radio-info radio-inline">
                  <input formControlName="gender" type="radio" name="gender" value="1" id="male">
                  <label for="male"> Male </label>
                </div>
              </fieldset>
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="submitted && registrationForm.controls?.gender.invalid">
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls.gender.errors.required">
                <b>Error:</b> Please add a gender.
              </div>
            </div>
          </div>
          <!-- birthday -->
          <div class="form-group birthday">
            <label for="inputPassword3" class="col-sm-3 control-label">Birthday *</label>
            <span class="tiny" #tooltip="matTooltip" matTooltip="You must be 18 years or older to utilize our services."
              matTooltipPosition="above"></span>
            <i class="fa fa-info-circle info-popup icon-date" (click)="tooltip.show()"></i>
            <div class="col-sm-4 register-row-wrapper">
              <mat-form-field appearance="fill" class="mat-form-wrapper">
                <mat-select #selectElement class="custom-select-registration" placeholder="Month"
                  formControlName="selectedMonth" (selectionChange)="setDays(false, selectElement.value)"
                  [disabled]="false">
                  <mat-option *ngFor="let month of months" [value]="month.value">
                    {{ month.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-2 custom-row-register register-row-wrapper">
              <mat-form-field appearance="fill" class="mat-form-wrapper">
                <mat-select #selectElement1 class="custom-select-registration" placeholder="Day"
                  formControlName="selectedDay" [disabled]="false"
                  (selectionChange)="setValueDay(selectElement1.value)">
                  <mat-option *ngFor="let day of days" [value]="day">
                    {{ day }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field appearance="fill" class="mat-form-wrapper">
                <mat-select #selectElement2 class="custom-select-registration" placeholder="Year"
                  formControlName="selectedYear" (selectionChange)="setDays(true, selectElement2.value)"
                  [disabled]="false">
                  <mat-option *ngFor="let year of years" [value]="year">
                    {{ year }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>


          <!-- Address -->
          <div class="form-group">
            <label class="col-sm-3 control-label">Home Address *</label>
            <div class="col-sm-9">
              <input formControlName="addressLine" type="text" class="form-control" placeholder="Home Address" />
            </div>
          </div>

          <!-- City -->
          <div class="form-group">
            <label for="city" class="col-sm-3 control-label">City *</label>
            <div class="col-sm-9">
              <input name="city" class="form-control" formControlName="city" type="text" id="city" placeholder="City" />
            </div>
            <div class="form-group col-md-12 pull-right" *ngIf="submitted && registrationForm.controls?.city.invalid">
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls.city.errors?.required">
                <b>Error:</b> Please add your city of address
              </div>
            </div>
          </div>



          <!-- State -->
          <div class="form-group">
            <label class="col-sm-3 control-label" for="stateId">State *</label>
            <div class="col-sm-9">
              <mat-form-field appearance="fill" class="mat-form-wrapper">
                <mat-select #selectElementState class="custom-select-registration" placeholder="State"
                  formControlName="state" (selectionChange)="setState(selectElementState.value)" [disabled]="false">
                  <mat-option *ngFor="let state of (stateList$ | async)" [value]="state.label">
                    {{ state.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!--<ui-select name="state" style="width: 200px" theme="bootstrap" ng-required="true" [(model)]="state">
                <ui-select-match placeholder="State">
                  <span ng-bind="$select.selected.name"></span>
                </ui-select-match>
                <ui-select-choices repeat="state in  (stateList | filter: $select.search) track by $index">
                  <span ng-bind="state.name"></span>
                </ui-select-choices>
              </ui-select>-->
            </div>
            <div class="form-group col-md-12 pull-right" *ngIf="registrationForm.controls?.state.invalid">
              <div class="alert alert-danger" role="alert" *ngIf="hasForbiddenStates"><b>Error:</b> Apologies, we are
                unable to serve customers from NJ,
                NY, AZ or RI.
              </div>
              <div class="form-group col-md-12 pull-right"
                *ngIf="submitted && registrationForm.controls?.state.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls.state.errors?.required">
                  <b>Error:</b> Please select your state of residence.
                </div>
              </div>
            </div>
          </div>





          <!-- Zip -->
          <div class="form-group">
            <label for="zip" class="col-sm-3 control-label">Zip *</label>
            <div class="col-sm-9">
              <input name="zip" class="form-control" formControlName="zip" type="text" id="zip" placeholder="Zip"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0, 5);" maxlength="5" />
            </div>
            <div class="form-group col-md-12 pull-right" *ngIf="submitted && registrationForm.controls?.zip.invalid">
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls.zip.errors?.required">
                <b>Error:</b> Please add your zip code.
              </div>
              <div class="alert alert-danger" role="alert" *ngIf="registrationForm.controls.zip.errors?.minlength">
                <b>Error:</b> 5 digit zip code required.
              </div>
            </div>
          </div>

          <!-- languagePreference -->
          <div class="form-group">
            <label for="languagePreference" class="col-sm-3 control-label custom-lenguage-label">language
              Preference</label>
            <div class="col-sm-9">
              <mat-form-field appearance="fill" class="mat-form-wrapper">
                <mat-select #selectElementLanguagePreference class="custom-select-registration"
                  placeholder="Language Preference" formControlName="languagePreference"
                  (selectionChange)="setLanguagePreference(selectElementLanguagePreference.value)" [disabled]="false">
                  <mat-option *ngFor="let language of languagePreference" [value]="language.id">
                    {{ language.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- Gender -->
          <div class="form-group">
            <label class="col-sm-12 control-label" style="text-align: left">I agree that I have reviewed and agree to
              the Grassroots Labs Terms and Conditions and Privacy Policy</label>
            <div class="col-sm-12">
              <fieldset>
                <div style="display: block">
                  <input formControlName="termsAndConditions" type="checkbox" class="checkbox-inline"
                    name="termsAndConditions" value="2" id="termsAndConditions">
                  <label for="termsAndConditions"> <a style="font-size:9.6px"
                      href="https://zachgrassrootslabs-my.sharepoint.com/:w:/g/personal/zach_grassrootslabs_com/ETbg2OiFnIdaM4D1U93MhWUBbJ0fTMYbrWsUy0gwRP5WjQ?e=Myti7s"
                      target="_blank">Terms and Conditions </a> </label>
                </div>

                <div style="margin-left: 0">
                  <input formControlName="privacyPolicy" type="checkbox" class="checkbox-inline" name="privacyPolicy"
                    value="1" id="privacyPolicy">
                  <label for="privacyPolicy"><a style="font-size:9.6px"
                      href="https://zachgrassrootslabs-my.sharepoint.com/:w:/g/personal/zach_grassrootslabs_com/EYaUQbv42i9fVngyyCL1FAoBSrBvt1dCJZPXiIBC8SjauA?e=xZrPIl"
                      target="_blank"> Privacy Policy </a></label>
                </div>
              </fieldset>
            </div>
            <div class="form-group col-sm-12 pull-right"
              *ngIf="submitted && (registrationForm.controls?.termsAndConditions.invalid || registrationForm.controls?.privacyPolicy.invalid)">
              <div class="alert alert-danger" role="alert"
                *ngIf="registrationForm.controls.termsAndConditions.errors?.required || registrationForm.controls.privacyPolicy.errors?.required">
                Please check to agree to the Grassroots Labs Terms and Conditions and Privacy Policy
              </div>
            </div>
            <div class="form-group col-sm-12 pull-right" *ngIf="submitted && registrationForm.invalid">
              <div class="alert alert-danger" role="alert"><b>Error:</b> Please fix the errors above and try to register
                again.</div>
            </div>
          </div>
          <ng-template #footerTemplate>
            <hr class="my-0">
            <button class="btn btn-sm btn-danger m-2" float-left (click)="clearDate()">Clear</button>
            <button class="btn btn-primary btn-sm m-2 float-left" (click)="model = today; d.close()">Today</button>
            <button class="btn btn-success btn-sm m-2 float-right" (click)="d.close()">Close</button>
          </ng-template>

          <div class="alert alert-warning password-alert" role="alert" *ngIf="showSuggestions || paswordInvalid"
            style="font-size: 15px;">
            <h6 style="font-size: 11px;">Password Help:</h6>
            <ul style="list-style-type: none;" >
              <li>
                <label class="col password-alert-display"
                  [ngClass]="colorMatch.hasNumber  ? 'text-success': 'text-danger'">
                  <i  class="material-icons"> {{ colorMatch.hasNumber ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must contain at least 1 number!</span>
                </label>
              </li>
              <li>
                <label class="col password-alert-display"
                  [ngClass]="colorMatch.hasCapitalCase  ? 'text-success': 'text-danger'">
                  <i class="material-icons"> {{ colorMatch.hasCapitalCase ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must contain at least 1 in Capital Case!</span>
                </label>
              </li>
              <li>
                <label class="col password-alert-display"
                  [ngClass]="colorMatch.hasSmallCase  ? 'text-success': 'text-danger'">
                  <i class="material-icons" > {{ colorMatch.hasSmallCase ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must contain at least 1 Letter in Small Case!</span>
                </label>
              </li>
              <li>
                <label class="col password-alert-display"
                  [ngClass]="colorMatch.hasSpecialCharacters  ? 'text-success': 'text-danger'">
                  <i class="material-icons" > {{ colorMatch.hasSpecialCharacters ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must contain at least 1 Special Character!</span>
                </label>
              </li>
              <li>
                <label class="col password-alert-display"
                  [ngClass]="colorMatch.minLength  ? 'text-success': 'text-danger'">
                  <i class="material-icons" > {{ colorMatch.minLength ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must be at least 8 characters!</span>
                </label>
              </li>
            </ul>
          </div>

          <div class="alert alert-warning password-alert" role="alert" *ngIf="showSuggestions2 || paswordInvalid"
            style="font-size: 15px;">
            <h6 style="font-size: 11px;">Password Help:</h6>
            <ul style="list-style-type: none;">
              <li>
                <label class="col password-alert-display"
                  [ngClass]="confirmColorMatch.hasNumber  ? 'text-success': 'text-danger'">
                  <i class="material-icons"  > {{ confirmColorMatch.hasNumber ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must contain at least 1 number!</span>
                </label>
              </li>
              <li>
                <label class="col password-alert-display"
                  [ngClass]="confirmColorMatch.hasCapitalCase  ? 'text-success': 'text-danger'">
                  <i class="material-icons"  > {{ confirmColorMatch.hasCapitalCase ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must contain at least 1 in Capital Case!</span>
                </label>
              </li>
              <li>
                <label class="col password-alert-display"
                       [ngClass]="colorMatch.hasSmallCase  ? 'text-success': 'text-danger'">
                  <i class="material-icons"  > {{ confirmColorMatch.hasSmallCase ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must contain at least 1 Letter in Small Case!</span>
                </label>
              </li>
              <li>
                <label class="col password-alert-display"
                  [ngClass]="confirmColorMatch.hasSpecialCharacters  ? 'text-success': 'text-danger'">
                  <i class="material-icons"  > {{ confirmColorMatch.hasSpecialCharacters ? 'check_circle' : 'cancel' }}
                  </i>
                  <span class="password-alert-padding"  >Must contain at least 1 Special Character!</span>
                </label>
              </li>
              <li>
                <label class="col password-alert-display"
                  [ngClass]="confirmColorMatch.minLength  ? 'text-success': 'text-danger'">
                  <i class="material-icons"  > {{ confirmColorMatch.minLength ? 'check_circle' : 'cancel' }} </i>
                  <span class="password-alert-padding"  >Must be at least 8 characters!</span>
                </label>
              </li>
            </ul>
          </div>

        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <!-- <div class="form-group col-sm-12 pull-right" *ngIf="submitted && registrationForm.invalid">
              <div class="alert alert-danger" role="alert" ><b>Error:</b> Please fix the errors above and try to register again.</div>
            </div> -->
            <div class="col-sm-12 text-right">
              <button type="submit" class="btn btn-default">REGISTER</button>
            </div>

          </div>
        </div>
      </div>
    </form>
    <!-- </ng-scrollbar> -->
  </div>
  <div class="clearfix"></div>
</div>
