import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BlogService} from "../../services/blog.service";
import {ModalService} from "../../services/modal.service";
import {environment} from "../../../environments/environment";
import {UploadService} from "../../services/upload.service";
import * as LZUTF8 from "lzutf8";
import {BlogArticle} from "../../entities/blog/BlogArticle";
import {ImageService} from "../../services/image.service";

declare var $;

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: [ './add-article.component.scss' ]
})


export class AddArticleComponent implements OnInit  {
  savedAuthors = [];
  addingNewAuthor = true;
  articleForm: UntypedFormGroup;
  editMode = false;
  submitted: boolean = false;
  previewImage = null;
  imageURL = environment.baseIconUrl;
  baseUploadUrl = environment.base;
  html = '';
  isAuthorValid = false;
  name = 'Angular 6';
  htmlContent = '';
  constructor( public dialogRef: MatDialogRef<AddArticleComponent>,
               private blogService: BlogService,
               private uploadService: UploadService,
               private modalService: ModalService,
               private imageService: ImageService,
               @Inject(MAT_DIALOG_DATA) public inputdata: any) { }



  config: any = {
    callbacks: {
      onInit: () => {
        $('.note-editable').css('font-size', '18px'); // Встановлюємо розмір шрифту за замовчуванням
      },
    },
    airMode: false,
    tabDisable: true,
    popover: {
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [
        ['link', ['linkDialogShow', 'unlink']]
      ],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear'
          ]
        ],
      ]
    },
    height: '200px',
    uploadImagePath: this.baseUploadUrl + '/api/image/blog/editor',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['picture', 'link', 'video', 'hr']],
      ['view', ['fullscreen', 'codeview']],
      ['custom', ['paddingBtn']]
    ],
    buttons: {
      paddingBtn: this.paddingButton
    },
    codeviewFilter: true,
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
    fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '24', '30', '36','72'],
   fontSize: "18px",
    lineHeight: 30
  };

  public summernoteInit(event) {
    const editor = $(event.target); // Отримуємо редактор
    editor.summernote('fontSize', 18); // Встановлюємо шрифт розміром 28
    console.log('Font size set to 28');
  }

  ngOnInit(): void {
    this.blogService.getAllAuthors().subscribe((response)=>{
      this.savedAuthors = response;
    });

    this.articleForm = new UntypedFormGroup({
      title:new UntypedFormControl('', Validators.compose([ Validators.required ])),
      shortDescription: new UntypedFormControl('', Validators.required),
      authorId: new UntypedFormControl(''),
      author: new UntypedFormControl(''),
      estimateLength: new UntypedFormControl('', Validators.required),
      text: new UntypedFormControl('', Validators.required),
      pageTitle: new UntypedFormControl(''),
      metaDescription: new UntypedFormControl(''),
      metaRobots: new UntypedFormControl(''),
      imageId: new UntypedFormControl(''),
      slug: new UntypedFormControl(''),
      isActive: new UntypedFormControl(true),
      newAuthorName: new UntypedFormControl(''),
    });
    if (this.inputdata.isEdit) {
      this.editMode = true;
      this.addingNewAuthor = false;

      this.articleForm.patchValue(this.decompressArticleContent(this.inputdata.article));
      this.previewImage = this.imageURL + this.inputdata.article.imageId;
    }
    this.articleForm.get('authorId')?.valueChanges.subscribe(() => this.updateAuthorValidity());
    this.articleForm.get('newAuthorName')?.valueChanges.subscribe(() => this.updateAuthorValidity());
  }

  updateAuthorValidity() {
    const authorId = this.articleForm.get('authorId')?.value;
    const newAuthorName = this.articleForm.get('newAuthorName')?.value;

    this.isAuthorValid = !!authorId || !!newAuthorName;
  }
  paddingButton(context) {
    const ui = $.summernote.ui;

    const paddingInput = $('<input type="number" value="0" placeholder="Enter padding in px" style="margin-left: 5px; width: 80px; display: none;" />');
    const applyButton = $('<button type="button" style="margin-left: 5px; display: none;">Apply</button>');
    const errorMessage = $('<div style="color: red; font-size: 12px; display: none;"></div>');

    const button = ui.button({
      contents: '<i class="note-icon-picture"></i> Padding',
      tooltip: 'Add padding to image',
      click: function () {
        paddingInput.show();
        applyButton.show();
        errorMessage.hide().text('');
      }
    });

    applyButton.on('click', () => {
      errorMessage.hide().text('');
      const $img = $(context.invoke('editor.restoreTarget'));

      if ($img && $img.is('img')) {
        let paddingValue = paddingInput.val();

        if (paddingValue === '') {
          paddingValue = '0';
        }
        if (!isNaN(Number(paddingValue)) && Number(paddingValue) >= 0) {
          let currentStyle = $img.attr('style') || '';
          currentStyle = currentStyle.replace(/padding:\s*\d+px;?/g, '').trim();
          $img.attr('style', `${currentStyle}; padding: ${paddingValue}px;`.trim());

          const updatedContent = context.invoke('code');

          context.invoke('code', updatedContent);

          paddingInput.hide().val('');
          applyButton.hide();
        } else {
          errorMessage.text('Please enter a valid number for padding.').show();
        }
      } else {
        errorMessage.text('Please select an image to apply padding.').show();
      }
    });

    paddingInput.on('input', function() {
      let paddingValue = paddingInput.val();
      if (paddingValue === '') {
        paddingValue = '0';
      }

      if (!isNaN(Number(paddingValue)) && Number(paddingValue) >= 0) {
        errorMessage.hide().text('');
      } else {
        errorMessage.text('Please enter a valid number for padding.').show();
      }
    });

    const buttonGroup = $('<div style="display: flex; flex-direction: column; align-items: flex-start;"></div>')
      .append($('<div style="display: flex; align-items: center;"></div>').append(button.render()).append(paddingInput).append(applyButton))
      .append(errorMessage);

    return buttonGroup;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  onDelete(file: any) {
    this.deleteResource(file.url);
  }

  deleteResource(url){
    this.imageService.deleteImage(this.getImageIdFromUrl(url));
  }

  getImageIdFromUrl(url: string): string | null {
    return url.match(/[^/]+$/)?.[0] || null;
  }

  onAuthorChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.addingNewAuthor = selectedValue === '';

    if (this.addingNewAuthor) {
      this.articleForm.controls['newAuthorName'].setValidators(Validators.required);
    } else {
      this.articleForm.controls['newAuthorName'].clearValidators();
    }
    this.articleForm.controls['newAuthorName'].updateValueAndValidity();
  }

  openGallery() {
    this.modalService.openBrowseImage().afterClosed().subscribe((result) => {
      this.previewImage = result.preview;
      this.articleForm.patchValue({ imageId: result.image });
    });
  }

  uploadFiles(files: File) {
    let formData = new FormData();

    formData.append('file', files[0], files[0]['name']);

    this.uploadService.uploadFilesForBlog(formData).subscribe((res: any) => {
      this.previewImage = `${ this.imageURL }${ res.id }`;
      this.articleForm.patchValue({ imageId: res.id });
    });
  }

  compressArticleContent(article: BlogArticle) {
    if (article.text) {
      article.text = LZUTF8.compress(article.text, { outputEncoding: "StorageBinaryString" });
      article.text += `${environment.compressedData}`;
    }
    return article;
  }

  decompressArticleContent(article: any) {
    if (article.text && article.text.includes(`${environment.compressedData}`)) {
      article.text = article.text.replace(`${environment.compressedData}`, '');
      article.text = LZUTF8.decompress(article.text, { inputEncoding: 'StorageBinaryString' });
    }
    return article;
  }

  submit(articleForm) {

    let editorHtml = $('#summernote').summernote('code');

    this.submitted = true;
    if (this.articleForm.invalid) {
      return;
    }
    let compressedArticle = this.compressArticleContent(articleForm.value);

    if (this.editMode){


      this.articleForm.value.id = this.inputdata.article.id;
      this.blogService.updateArticle(compressedArticle).subscribe((data)=>{
        this.cancel();
        this.modalService.showTopNotification(true, `Article was successfully updated`);
      });

    }
    else {
      this.blogService.addArticle(compressedArticle).subscribe((data)=>{
        this.cancel();
        this.modalService.showTopNotification(true, `Article was successfully added`);
      });
    }
  }
}
